<template>
  <div id="container" :class="{ contain: true, mobile: isMobile }">
    <div class="cont d-flex flex-lg-row flex-wrap ">
      <div
        class="indexfan d-flex flex-column"
        v-for="(item, index) in followList"
        :key="index"
        v-scroll="onScroll"
      >
        <div class="ava d-flex justify-center mt-6 mt-lg-6 mb-2 mb-lg-2">
          <Avatar
            style="margin: 0 auto"
            :size="116"
            v-if="!isMobile"
            :hash="item.userPhoto"
            :grade="item.userGrade"
			      imgType="especially"
            showAvatarInfo
            :did="item.userAddress"
          />
        </div>
        <div class="name">{{item.userName ?item.userName:item.userAddress | namefilter}}</div>
        <div class="num">{{ $t("avatarlistWork") }} {{ item.workCount }}</div>
        <div
          class="msg mt-3 ml-0 mt-lg-6 mr-lg-6"
          @click.stop="msgBtn(item)"
        >
          <img src="@/assets/icons/btn_message.png" alt="" />
        </div>
      </div>
    </div>
    <loading :isShowLoading="isShowLoading"></loading>
    <div v-if="open" style="position: fixed; right: 20px; bottom: 20px; z-index: 500;">
         <message ref="message" @getOpen="Open" v-if="open" :currfriend="currfriend"></message>
      </div>
  </div>
</template>

<script>
import api from "@/api";
import Loading from "@/components/loading.vue";
import message from "@/components/popup/message.vue";
import Avatar from "@/components/avatar/index.vue";
export default {
  name: "follows",
  components: { message, Avatar, Loading },
  data: () => ({
    isStatus: false,
    followList: [],
    pageNumber: 0,
    pageSize: 24,
    totalPage: false,
    isShowLoading: false,
    open: false,
    currfriend:{},
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  filters: {
  	namefilter: function(value) {
		if(value && value.length > 12) {
			return value.substr(0,5)+"..."+value.substr(-5);
		} else {
			return value;
		}
  	}
  },
  methods: {
    msgBtn(msg) {
      this.currfriend = {
        fansAddress: msg.userAddress,
        fansName: msg.userName,
      };
      this.open = true;
       msg.wordsStatus = 1;
      this.$refs.message.init(this.currfriend);
      setTimeout(() => { window.eventBus.$emit("ViewMessage", true) }, 1000);
    },
    //弹框关闭后获取open值
    Open(e) {
      this.open = e;
      window.eventBus.$emit("ViewMessage", true);
    },
    async getFollow() {
      this.pageNumber++;
      let params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        userAddress: this.$store.state.did,
        //userAddress:'iaa1l4799la336dlgjvpmf3avel9xel4u47k7sk45m'
      };
      this.isShowLoading = true;
      let res = await api.home.myFocus(params);
      let list = res.data.list;
      this.followList = this.followList.concat(list);
      this.totalPage = res.data.totalPage;

      this.isShowLoading = false;
    },
    onScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } =
        e.target.scrollingElement;

      if (
        scrollTop + clientHeight >= scrollHeight &&
        this.totalPage > this.pageNumber
      ) {
        this.getFollow();
      }
    },
  },
  mounted() {
    this.getFollow();
  },
};
</script>

<style lang="scss" scoped>
#container {
  .title {
    width: 129px;
    height: 21px;
    font-family: "\5FAE\8F6F\96C5\9ED1";
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 50px;
    letter-spacing: 0px;
    color: #270645;
  }
  .cont {
    max-width: 1216px;
    margin: 0 0 48px;
    .indexfan {
      cursor: pointer;
      width: 180px;
      height: 206px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#6f58d9, #6f58d9);
      background-blend-mode: normal, normal;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
      border-radius: 5px;
      margin-right: 26px;
      margin-top: 20px;

      &:nth-child(6n) {
        margin-right: 0;
      }
    }
    .ava {
      width: 100%;
    }
    .num {
      font-family:Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      width: 100%;
      text-align: center;
    }
    .name {
      width: 100%;
      text-align: center;
      font-family:Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    .msg {
      display: none;
      position: relative;
      img {
        position: absolute;
        right: -10px;
        top: -206px;
        width: 25px;
        height: 24px;
      }
    }
    .indexfan:hover .msg {
      display: block;
    }
  }
}
</style>
